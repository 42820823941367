<template>
    <div class="home">
        <div class="date_box">
            <div class="one">
                <img  class="photo"  v-lazy="photo" alt="">
              
                            <div >
                                <div class="name">{{dispname}}</div>
                            </div>
            </div>
        
            <div class="tab_box">
                <div v-for="(item,index) in typeList" :key="index" :class="item.isselected?'act_tab_item':'tab_item'" @click="changeTab(item.type,index)">{{ item.typename }}</div>
                <!-- <div :class="qrytype==1?'act_tab_item':'tab_item'" @click="changeTab(1)">理疗预约</div>
                <div :class="qrytype==3?'act_tab_item':'tab_item'" @click="changeTab(3)">体检预约</div> -->
            </div>
            <div class="bg_box">
                <div class="top_date"><span>就诊日期</span> {{ qrydate }}</div>
                  <div class="my_date_box">
                 <div  v-for="(item,index) in date"  :key="index"  class="date_item">
                        <div class="week">{{ item.week }}</div>
                        <div class="day" @click="changeDay(item.date,item.week,item.otherlist,item.date_text)"> <span :class="item.label=='休'?'label':'label_common'">{{ item.label }}</span><div :class="qrydate==item.date?'act_label':''">{{ item.day }}</div></div>
                        <!-- <div>{{ item.day }}</div> -->
            </div>
            </div>
            <div style="font-size: 13px;"> 
                <span class="intro">就诊日期：</span>{{ date_text }} 星期{{ week_text }}<br>
                <span class="intro">当前医馆：</span>{{ clinicname }}
            </div>
           
            <div class="time_box" v-if="list.length>0">
                <div v-for="(item,index) in list" :key="index" class="time_item" @click="toAppointment(item.overtime,item.planid,item.surplusnum)">
                <div class="timelabel">{{ item.timelabel }}</div> 
                <div class="timelabel">{{ item.starttime }}</div>~
                <div class="timelabel">{{ item.endtime }}</div> 
                <div class="fee" style="margin-right: 15px;">￥{{ item.fee }}</div> 
                <div class="state" v-if="item.surplusnum<=0">已满诊</div> 
                <div class="state" v-if="item.surplusnum>0&&item.overtime==='true'" >已截止</div> 
                <div class="state" v-if="item.surplusnum>0&&item.overtime==='false'"><span style="color:#333">余</span><span class="surplusnum">{{ item.surplusnum }}</span>   <i class="icon iconfont iconsanjiaoyou"></i></div> 
            </div>
            </div>
            </div>
            <div class="no_box"  v-if="!otherlist&&!list.length">
                <img src="@/assets/暂无排班.png" alt="" style="height: 115px;width: 170px;"> 
            </div>
            <div class="no_box" style="margin-bottom:0px"  v-if="otherlist&&otherlist.length>0">
               
                     <img src="@/assets/其他分馆.png" alt="" style="height: 80px;width: 160px;"> 
            </div>
            <div class="no_box" style="padding: 0px 60px 10px;" v-if="otherlist&&otherlist.length>0">
                    <div>该医生在{{ clinicname }}无排班信息请留意其他分馆</div>
                
            </div>
        
          <div class="bg_box" style="border-radius:10px" v-if="otherlist&&otherlist.length>0">
            <div class="otherlist"  v-for="(item,index) in otherlist" :key="index" >
                {{item.clinicname }} <div @click="changeHospital(item.clinicid)">查看预约</div>
            </div>      
          </div>
           <h3 v-if="intro" style="margin-bottom:8px">擅长</h3>
           <div class="intro"  v-if="intro">
            擅长：{{ intro }}
           </div>
    
        </div>
        <van-overlay :show="show_loading" @click="show = false" >
            <div class="wrapper" @click.stop>
                <van-loading size="24px" vertical>加载中...</van-loading>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import {wxappointmentdatebydoctor,wxappointmentplanbydoctor,wxappointmenttypebydoctor} from '@/api/medical'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { Lazyload } from 'vant';
import Vue from 'vue';
import errorImg from '@/assets/医生头像.png'
Vue.use(Lazyload, {
        lazyComponent: true,
        loading: "https://yz.iyzzy.com/icon/loading.gif",
        error: errorImg
    });

export default{
    computed: {
        ...mapGetters([
            'username','clinicid'
        ]),
    },
    data(){
        return{
            date:[],
            qrytype:0,
            doctorname:'',
            qrydate:'',
            photo:'',
            dispname:'',
            week:'',
            week_text:'',
            date_text:'',
            clinicname:'',
            now_time:  dayjs(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss'),
            intro:'',
            typeList:[],
            list:[],
            otherlist:[],
            show_loading:false
        }
    },
    created(){
      
        this.$store.dispatch('hideOrShowNav', false)
        this.doctorname = sessionStorage.getItem('doctorname')
        this.qrytype = sessionStorage.getItem('qrytype')
        this.qrydate = this.$route.query.qrydate || ''
        this.photo = sessionStorage.getItem('photo')
        this.dispname = sessionStorage.getItem('dispname')
        this.intro = sessionStorage.getItem('intro')
        this.other_clinicid = this.clinicid
        this.getType()
        this.getdate()
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth' 
        });
    },

    methods:{
        getType(){
            let data ={
                username:this.username,
                qrydate:this.qrydate,
                doctorname: this.doctorname,
                clinicid:this.other_clinicid,
                qrytype:this.qrytype,
            }
            this.show_loading = true
            wxappointmenttypebydoctor(data).then(response =>{
                this.typeList = response.response_body.list
            })
            // this.show_loading = false
        },
        changeHospital(clinicid){
            this.other_clinicid = clinicid
            this.getType()
            this.getdate()
        },
        getdate(){
            this.show_loading = true
            let data ={
                username:this.username,
                qrydate:this.qrydate,
                doctorname: this.doctorname,
                clinicid:this.other_clinicid,
                qrytype:this.qrytype,
            }
            wxappointmentdatebydoctor(data).then(response =>{
                  
                this.date = response.response_body.list
                this.date.forEach((item,index)=>{
                    this.date[index].date_text = item.date
                    this.date[index].date = item.date.replace('年','-').replace('月','-').replace('日','')
                })

                let i_date= this.date.find((item)=>item.date==this.qrydate);
                if(!i_date){
                    i_date = this.date[0]
                }
                this.week = i_date.week
                this.changeDay(this.qrydate,this.week,i_date.otherlist,i_date.date_text);
            
            })
            // this.show_loading = false
        },
        changeDay(date,week,otherlist,date_text){
            this.qrydate = date
            this.week = week
            this.otherlist = otherlist
            this.date_text = date_text
            this.getList();
        },
        changeTab(type,selected_index){
            this.qrytype = type;
            this.getdate();
            this.typeList.forEach((item,index)=>{
                item.isselected = 0
                if(selected_index==index){
                    item.isselected = 1
                }
            })
        },
        getList(){
            let data={
                username:this.username,
                qrydate:this.qrydate,
                doctorname:this.doctorname,
                clinicid:this.other_clinicid,
                qrytype:this.qrytype,
            }
            this.show_loading = true
            wxappointmentplanbydoctor(data).then(response =>{
                  this.week_text = response.response_body.week
                  this.clinicname = response.response_body.clinicname
                  this.list = response.response_body.list
                //   this.list.forEach((item,index)=>{
                //     this.list[index].end_date_time =  this.qrydate+' '+item.endtime
                // })
                
                this.now_time = dayjs(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')

            })
            this.show_loading = false
        },
        toAppointment(overtime,planid,surplusnum){
            // this.now_time = dayjs(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss')
            if(overtime==='true'||surplusnum<=0){
                return
            }else{
                sessionStorage.setItem('qrytype',this.qrytype)
                this.$router.push({
                    path: '/appointment',
                    query: {
                        planid:planid,
                    }
                })
            }
        }

    }
}

</script>
<style lang="scss" scoped>
.home{
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    background: rgb(246, 246, 244);
    height: 100vh;
    overflow:auto;

    .tab_box{
        display: flex;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        width: 100%;
        margin-top: 10px;
    }
    .tab_item{
        flex: 1;
        font-size: 18px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #c2c0b9;
        color: #fff;
    }
    .act_tab_item{
        flex: 1;
        font-size: 18px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: rgb(207,140,87);
        color: #fff;
    }
    .week{
        font-size: 16px;
        width: 45px;
        text-align: center;
        color: #999;
        line-height: 30px;
    }
    .bg_box{
        background-color: #fff;
        padding: 15px;
        margin-bottom: 15px;
    }
    .my_date_box{
        display: flex;
        width: 100%;
        overflow-x: auto;
        height: 100px;
        margin-bottom: 10px;
      
    }
    ::-webkit-scrollbar-track-piece {
      background-color: #f1f1f1;
      border-left: 1px solid #e6ebf5;
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        background-clip: padding-box;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        min-height: 28px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #787878;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;

    }
    .day{
        width: 45px;
        text-align: center;
        font-size: 18px;
        font-family: MATH;
        color: #333;
        position: relative;
        // line-height: 60px;
        height: 40px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .label{
        width: 45px;
        font-size: 12px;
        min-height: 16px;
        color: rgb(207,140,87);
        overflow:visible;
        text-align: end;
        writing-mode: vertical-rl; 
        position: absolute;
        line-height: 18px;
        top: -7px;
        
    }
    .label_common{
        width: 45px;
        font-size: 12px;
        min-height: 16px;
        color: #8b8a87;
        overflow:visible;
        text-align: end;
        writing-mode: vertical-rl; 
        position: absolute;
        line-height: 18px;
        top: -7px;
    }
    .list{
        width: 33%;
        text-align: center;
        margin-bottom: 40px;
        .img{
            display: flex;
            justify-content: center;
        }
        img{
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
    }
    .date_box{
        border-radius: 10px;
        width: 100%;
        background-color: rgb(246, 246, 244);
    }
    .date_item{
        width: 80px;
    }
    .act_label{
        background-color: rgb(207,140,87);
        color: #fff;
        padding: 4px 4px;
        border-radius: 33px;
        width: 30px;
        height: 30px;
        margin-top: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .photo{
        width: 2rem;
        height: 2.5rem;
        border-radius: 10px;
        margin-right: 10px;
    }
    .one{
        display: flex;
    }
    .name{
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
    .top_date{
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 16px;
        font-family: MATH;
        span{
            font-size: 14px;
        }
    }
    .time_box{
        margin-top: 10px;
        font-size: 15px;
        line-height: 0.4rem;
    }
    .time_item{
        display: flex;
        align-items: center;
        border-top: #e0e0df solid 1px;
        height: 50px;
        width: 100%;
        justify-content: space-between;
    }
    .timelabel{

    }
    .fee{
        width: 100px;
        text-align: end;
        color: rgb(207,140,87);
    }
    .surplusnum{
        color: rgb(207,140,87);
    }
    .state{
        width: 70px;
        color: #999;
        text-align: end;
        display: flex;
        align-items: center;
        justify-content: end; 
        line-height: 21px;
    }
    .iconsanjiaoyou{
        font-size: 26px;
        width: 15px;
    }
    .intro{
       color: #999995;
       white-space: pre-wrap;
       line-height: 0.65rem;
       word-break:break-all
    }
    .otherlist{
        display: flex;
        justify-content: space-between;
        div{
            color:  rgb(207,140,87);
        }
    }
    .no_box{
        border-radius: 10px;
        width: 100%;
        padding: 15px;
        text-align: center;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
            color: #999995;
        }
        
    }
    .van-overlay{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>